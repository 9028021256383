import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Slider from 'components/Slider'
import VacationRentalsFeatures from 'components/Vacation-rentals-features'
import MobileFeature from 'components/Mobile-feature'
import BlogCarousel from 'components/Blog-carousel'
import FAQ from 'components/FAQ'
import Pricing from 'components/Calculator/Simple'
import CtaTrial from 'components/CTA/Trial'

const MainContent = styled.main`
  background-color: #ffffff;
`
const VacationRentals = ({ data, pageContext, location }) => {
  const {
    slides: { edges: slides },
    vacationRentals: {
      featured_media,
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      mainFeatures,
      calculator: { calculator_data },
      mobile: { mobile_app },
      faqs: {
        faq: { title: faqTitle, questions }
      },
      ctaData: { cta }
    },
    carouselPosts: { edges: carouselPosts }
  } = data

  const featuredImage = featured_media !== null ? featured_media?.localFile?.childImageSharp : null
  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
  image: featuredImage ? featuredImage.resize : null,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        image={seo.image}
        lang={seo.lang}
        location={location}
      />
      <Header mode="light" locale={pageContext.locale} color={'#FFFFFF'} opacity={0.3} location={location} />
      <Slider slides={slides} locale={pageContext.locale} location={location} />
      <MainContent>
        <VacationRentalsFeatures data={mainFeatures} />
        <MobileFeature data={mobile_app} locale={pageContext.locale} eventLocation="vacation_rentals"/>
        <BlogCarousel posts={carouselPosts} locale={pageContext.locale} location={location} />
        <Pricing
          calculator={calculator_data}
          buttonLink={cta.button_link}
          locale={pageContext.locale}
          location={location}
        />
        <FAQ questions={questions} title={faqTitle} />
        <CtaTrial data={cta} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default VacationRentals

export const query = graphql`
  query($id: Int, $locale: String!) {
    vacationRentals: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 85, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      mainFeatures: acf {
        text
        intro
        list {
          text
        }
        key_features {
          title
          features {
            title
            description
          }
        }
      }
      calculator: acf {
        calculator_data {
          calculator_title
          button_text
        }
      }
      mobile: acf {
        mobile_app {
          title
          description
        }
      }
      faqs: acf {
        faq {
          title
          questions {
            title
            content
          }
        }
      }
      ctaData: acf {
        cta {
          title
          description
          button_text
          button_link
          image_text
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    slides: allWordpressWpSlider(
      filter: { lang: { eq: $locale }, slider_category: { elemMatch: { name: { eq: "Vacation Rentals" } } } }
    ) {
      edges {
        node {
          id
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          acf {
            title
            owner
            role
            button_text
            url_link
            link_to_post {
              post_name
            }
          }
        }
      }
    }

    carouselPosts: allWordpressPost(
      filter: { tags: { elemMatch: { slug: { eq: "vacation-rentals" } } }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          slug
          acf {
            name_of_property
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 320) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
